// color mix function
@function tint-color($color, $weight) {
    @return mix(white, $color, $weight);
}

@function shade-color($color, $weight) {
    @return mix(black, $color, $weight);
}

// utilities function
@mixin generate-utility($utility, $infix, $is-rfs-media-query: false) {
    $values: map-get($utility, values);

    @if type-of($values) == "string" or type-of(nth($values, 1)) != "list" {
        $values: zip($values, $values);
    }

    @each $key, $value in $values {
        $properties: map-get($utility, property);

        @if type-of($properties) == "string" {
            $properties: append((), $properties);
        }

        $property-class: if(map-has-key($utility, class), map-get($utility, class), nth($properties, 1));
        $property-class: if($property-class == null, "", $property-class);

        $state: if(map-has-key($utility, state), map-get($utility, state), ());

        $infix: if($property-class == "" and str-slice($infix, 1, 1) == "-", str-slice($infix, 2), $infix);

        $property-class-modifier: if($key, if($property-class == "" and $infix == "", "", "-") + $key, "");

        @if map-get($utility, rfs) {
            @if $is-rfs-media-query {
                $val: rfs-value($value);

                $value: if($val == rfs-fluid-value($value), null, $val);
            } @else {
                $value: rfs-fluid-value($value);
            }
        }

        $is-rtl: map-get($utility, rtl);

        @if $value != null {
            @if $is-rtl == false {
            }
            .#{$property-class + $infix + $property-class-modifier} {
                @each $property in $properties {
                    #{$property}: $value !important;
                }
            }

            @each $pseudo in $state {
                .#{$property-class + $infix + $property-class-modifier}-#{$pseudo}:#{$pseudo} {
                    @each $property in $properties {
                        #{$property}: $value !important;
                    }
                }
            }
            @if $is-rtl == false {
            }
        }
    }
}
