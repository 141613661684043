@import "accordion";
@import "board";
@import "button";
@import "flag";
@import "form";
// @import "fullpage";
@import "input";
@import "list";
@import "modal";
@import "pagination";
@import "selectbox";
@import "slider";
@import "tab";
@import "table";
// @import "text";