/* board */
.boardview-wrap {
    border-top:1px solid $basic;
    .board-head {
        border-bottom:1px solid $border;
        text-align:center;
        .tit {
            padding:30px 40px;
            @include font-title;
            font-weight:700;
            @include mobile {
                padding:20px;
            }
        }
        .info {
            padding:0 40px 20px;
            font-size:0;
            white-space:nowrap;
            @include mobile {
                padding:0 20px 10px;
            }
            li {
                display:inline-block;
                vertical-align:top;
                color:$cont;
                @include font-sm;
                &::before {
                    content:' ';
                    display:inline-block;
                    vertical-align:top;
                    width:1px;
                    height:12px;
                    margin:4px 15px 0;
                    background:$border;
                }
                &:first-child::before {
                    display:none;
                }
            }
        }
    }
    .board-content {
        padding-top:30px;
        border-bottom:1px solid $border;
        @include mobile {
            padding-top:20px;
        }
        .img {
            margin:60px 0;
            @include tablet {
                margin:50px 0;
            }
            @include mobile {
                margin:30px 0;
            }
            &:first-child {
                margin-top:0;
            }
        }
        .cont {
            padding:0 40px;
            @include font;
            color:$cont;
            @include mobile {
                padding:0 20px;
            }
            &:last-child {
                padding-bottom:60px;
                @include tablet {
                    padding-bottom:50px;
                }
                @include mobile {
                    padding-bottom:30px;
                }
            }
        }
        .files {
            margin-top:60px;
            padding:15px 40px;
            border-top:1px solid $border;
            @include clearfix;
            @include mobile {
                margin-top:40px;
                padding:10px 20px;
            }
            .tit {
                float:left;
                @include font;
                color:$black;
                font-weight:700;
            }
            .file-lists {
                margin-left:100px;
                padding:1px 0;
                font-size:0;
                @include mobile {
                    margin-left:80px;
                    padding:0;
                }
                li {
                    display:inline-block;
                    vertical-align:middle;
                    margin-right:20px;
                    @include mobile {
                        display:block;
                        margin-top:5px;
                        &:first-child {
                            margin-top:0;
                        }
                    }
                    a {
                        display:block;
                        @include font-sm;
                        color:$gray;
                        @include pc {
                            &:hover {
                                text-decoration:underline;
                            }
                        }
                    }
                }
            }
        }
    }
    .board-foot {
        .more-view {
            display:flex;
            justify-content:space-between;
            align-items:center;
            padding:16px 0;
            border-bottom:1px solid $border;
            @include mobile {
                padding:10px 0;
            }
            li {
                position:relative;
                flex:1;
                width:50%;
                &.prev {
                    padding-left:40px;
                }
                &.next {
                    padding-right:40px;
                }
                &::before {
                    content:' ';
                    position:absolute;
                    top:50%;
                    width:12px;
                    height:12px;
                    margin-top:-6px;
                    border:1px solid $cont;
                    border-right:none;
                    border-bottom:none;
                }
                &.prev::before {
                    transform:rotate(-45deg);
                    left:20px;
                }
                &.next::before {
                    transform:rotate(-225deg);
                    right:20px;
                }
                dl {
                    display:flex;
                    align-items:center;
                    dt {
                        white-space:nowrap;
                        @include font;
                        font-weight:700;
                    }
                    dd {
                        white-space:nowrap;
                        padding:0 10px;
                        overflow:hidden;
                        a {
                            display:inline-block;
                            max-width:100%;
                            color:$gray;
                            @include font-sm;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            vertical-align:middle;
                            @include pc {
                                &:hover,
                                &:active {
                                    text-decoration:underline;
                                }
                            }
                        }
                    }
                }
                &.next dl {
                    flex-direction:row-reverse;
                }
            }
        }
    }

    &.qna-type {
        .tit {
            position:relative;
            padding-left:40px;
            @include mobile {
                padding-left:20px;
            }
            &::before {
                content:' ';
                position:absolute;
                top:0;
                left:0;
            }
        }
        .q-box .tit::before {
            content:'Q.';
        }
        .a-box {
            .board-head {
                background:transparent;
                .tit::before {
                    content:'A.';
                }
            }
        }
        .board-content .cont {
            padding-left:80px;
            @include mobile {
                padding-left:40px;
            }
        }
    }
}

.board-btn {
    position:relative;
    text-align:center;
    @include mobile {
        margin-top:30px;
    }
    .btn {
        position:absolute;
        right:0;
        top:-114px;
        @include mobile {
            position:relative;
            right:auto;
            top:auto;
            width:100%;
        }
    }
}

/* notice */
.notice-lists {
    padding-top:30px;
    border-top:1px solid $border;
    ul.lists {
        display:flex;
        flex-wrap:wrap;
        @include calcs(margin-top, -40px, 1400px);
        @include calcs(margin-left, -50px, 1400px);
        li {
            width:calc(100% / 3);
            @include calcs(margin-top, 40px, 1450px);
            @include calcs(padding-left, 50px, 1450px);
            @include mobile {
                width:100%;
            }
            a.inner {
                display:block;
                padding:30px;
                box-shadow:0px 0px 15px 0px rgba(217, 217, 217, 0.75);
                @include tablet {
                    padding:20px;
                }
                .flag {
                    margin-bottom:5px;
                }
                .count {
                    display:block;
                    margin-bottom:16px;
                    font-size:16px;
                    font-weight:700;
                    @include skew;
                    @include mobile {
                        font-size:14px;
                        margin-bottom:10px;
                    }
                }
                .tit {
                    @include font-md;
                    height:25px * 2;
                    font-weight:900;
                    @include textEllipsisClamp(2);
                    @include mobile {
                        height:23px * 2;
                    }
                }
                .desc {
                    margin-top:15px;
                    @include font;
                    height:24px * 6;
                    color:$gray;
                    @include textEllipsisClamp(6);
                    @include mobile {
                        height:auto;
                    }
                }
                .date {
                    margin-top:40px;
                    font-size:16px;
                    font-weight:700;
                    @include skew;
                    @include mobile {
                        font-size:14px;
                        margin-top:30px;
                    }
                }
            }
        }
    }
}

/* event */
.event-lists {
    overflow:hidden;
    ul.lists {
        display:flex;
        flex-wrap:wrap;
        @include calcs(margin-top, -40px, 1400px);
        @include calcs(margin-left, -50px, 1400px);
        li {
            width:50%;
            @include calcs(margin-top, 40px, 1450px);
            @include calcs(padding-left, 50px, 1450px);
            a.inner {
                display:block;
                img {
                    width:100%;
                }
            }
        }
    }
}

/* review */
.interview-video-lists {
    position:relative;
    padding:40px 60px 0;
    background:$disabled;
    @include tablet {
        padding:40px 30px 0;
    }
    @include mobile {
        padding:30px 20px 0;
    }
    &::before {
        content:' ';
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        height:92px;
        background:$white;
        @include mobile {
            height:65px;
        }
    }
    h4.title {
        margin-bottom:35px;
        font-size:24px;
        font-weight:900;
        @include tablet {
            margin-bottom:25px;
        }
        @include mobile {
            margin-bottom:10px;
            font-size:20px;
        }
    }
    .swiper-container {
        .swiper-slide {
            a.inner {
                display:block;
                .thumb {
                    position:relative;
                    display:block;
                    width:100%;
                    @include calcs(padding-top, 9px, 16px);
                    background-size:cover;
                    background-repeat:no-repeat;
                    background-position:center;
                    &::before {
                        content:' ';
                        position:absolute;
                        top:0;
                        right:0;
                        bottom:0;
                        left:0;
                        background:rgba(#000, 0.4);
                        z-index:1;
                    }
                    &::after {
                        content:' ';
                        position:absolute;
                        top:50%;
                        left:50%;
                        width:72px;
                        height:72px;
                        margin-top:-36px;
                        margin-left:-36px;
                        background:url('/images/icon/icon-play.png');
                        z-index:10;
                    }
                }
                .tit {
                    padding:25px 30px;
                    background:$disabled;
                    font-size:24px;
                    line-height:1;
                    @include textEllipsis;
                    @include mobile {
                        padding:15px 20px;
                        font-size:18px;
                    }
                    small {
                        display:block;
                        margin-bottom:5px;
                        font-size:0.58em;
                        line-height:1;
                        font-weight:900;
                        @include skew;
                        @include mobile {
                            font-size:12px;
                        }
                    }
                }
            }
            .info {
                display:none !important;
            }
        }
    }
    .controls {
        position:absolute;
        top:40px;
        right:60px;
        font-size:0;
        text-align:right;
        @include tablet {
            right:30px;
        }
        @include mobile {
            top:25px;
            right:20px;
        }
        .btn-control {
            position:relative;
            display:inline-block;
            width:26px;
            height:30px;
            vertical-align:top;
            &::before {
                content:' ';
                position:absolute;
                margin-top:-8px;
                border-top:8px solid transparent;
                border-bottom:8px solid transparent;
            }
            &.prev {
                &::before {
                    right:6px;
                    border-right:12px solid $basic;
                }
                &:hover::before {
                    border-right-color:$point;
                }
            }
            &.next {
                &::before {
                    left:6px;
                    border-left:12px solid $basic;
                }
                &:hover::before {
                    border-left-color:$point;
                }
            }
            &.swiper-button-disabled {
                opacity:0.3;
            }
        }
    }
}

.interview-lists {
    padding-top:30px;
    padding-bottom:50px;
    border-top:1px solid $border;
    border-bottom:1px solid $border;
    overflow:hidden;
    ul.lists {
        display:flex;
        flex-wrap:wrap;
        @include calcs(margin-top, -80px, 1400px);
        @include calcs(margin-left, -40px, 1400px);
        li {
            width:25%;
            @include calcs(margin-top, 80px, 1440px);
            @include calcs(padding-left, 40px, 1440px);
            @include tablet {
                width:50%;
            }
            @include mobile {
                width:100%;
            }
            a.inner {
                display:block;
                .thumb {
                    display:block;
                    width:100%;
                    @include calcs(padding-top, 210px, 320px);
                    background-size:cover;
                    background-repeat:no-repeat;
                    background-position:center;
                }
                .tit {
                    margin-top:20px;
                    font-size:24px;
                    line-height:1;
                    small {
                        display:block;
                        margin-bottom:5px;
                        font-size:0.58em;
                        line-height:1;
                        font-weight:900;
                        @include skew;
                    }
                    @include mobile {
                        font-size:18px;
                        small {
                            font-size:12px;
                        }
                    }
                }
                .desc {
                    margin-top:20px;
                    @include font;
                    @include textEllipsisClamp(3);
                    @include mobile {
                        margin-top:10px;
                    }
                }
            }
        }
    }
}

.review-lists {
    border-top:1px solid $border;
    > li {
        border-bottom:1px solid $blur;
        padding:15px 0;
        .inner {
            display:flex;
            align-items:center;
            @include mobile {
                align-items:flex-start;
            }
            .thumb {
                display:block;
                width:150px;
                height:150px;
                background-size:cover;
                background-repeat:no-repeat;
                background-position:center;
                @include mobile {
                    width:80px;
                    height:80px;
                }
            }
            .conts {
                width:calc(100% - 170px);
                margin-left:auto;
                @include mobile {
                    width:calc(100% - 100px);
                }
                .top {
                    display:flex;
                    align-items:center;
                    margin-bottom:10px;
                    @include mobile {
                        flex-wrap:wrap;
                    }
                    .flag {
                        width:70px;
                        color:$white;
                        &.spot01 {
                            background:$point;
                        }
                        &.spot02 {
                            background:#694dd1;
                        }
                        &.spot03 {
                            background:#4dd1c9;
                        }
                        &.spot04 {
                            background:#4d94d1;
                        }
                    }
                    .by {
                        padding:0 10px;
                        font-size:20px;
                        line-height:1;
                        font-weight:900;
                        @include mobile {
                            font-size:16px;
                            @include skew;
                        }
                    }
                    .info {
                        margin-left:auto;
                        font-size:0;
                        @include mobile {
                            width:100%;
                            margin-top:5px;
                        }
                        li {
                            display:inline-block;
                            vertical-align:top;
                            margin-left:20px;
                            @include font;
                            color:$gray;
                            font-weight:700;
                            @include mobile {
                                display:block;
                                margin-left:0;
                            }
                        }
                    }
                }
                .desc {
                    @include font-md;
                    min-height:25px * 3;
                    word-break:keep-all;
                    @include mobile {
                        min-height:0;
                    }
                }
                .date {
                    margin-top:10px;
                    font-size:16px;
                    @include skew;
                    color:$placeholder;
                    font-weight:700;
                    @include mobile {
                        font-size:14px;
                    }
                }
            }
        }
    }
}