/* button */
.btn {
    display:inline-block;
    padding:0 14px;
    border:1px solid transparent;
    border-radius:$border-radius;
    background:transparent;
    font-size:16px;
    line-height:38px;
    vertical-align:top;
    cursor:pointer;
    transition:$btn-transition;
    white-space:nowrap;
    text-align:center;
    @include skew;
    @include mobile {
        font-size:14px;
    }
    span {
        display:inline-block;
        vertical-align:top;
    }
    &.rounded {
        border-radius:20px;
    }
    &.no-round {
        border-radius:0 !important;
    }
    // style
    @each $color, $value, $ratio in $colors {
        &.btn-#{$color} {
            background-color:$value;
            border-color:$value;
            @if $ratio == 'dark' {
                color:$white;
            } @else if $ratio == 'bright' {
                color:$basic;
            }
            @include pc {
                &:hover, 
                &:active {
                    @if $ratio == 'dark' {
                        background-color:tint-color($value, 15%);
                        border-color:tint-color($value, 15%);
                    } @else if $ratio == 'bright' {
                        background-color:shade-color($value, 10%);
                        border-color:shade-color($value, 10%);
                    }
                }
            }
        }
        &.btn-border-#{$color} {
            border-color:$value;
            color:$value;
            @include pc {
                &:hover, 
                &:active {
                    background-color:rgba($value, .1);
                }
            }
        }
    }
    // size
    @each $size, $fontsize, $lineheight, $mfontsize, $pd, $mlineheight in $btn-sizes {
        &.btn-#{$size} {
            padding:0 $pd;
            font-size:$fontsize;
            line-height:$lineheight;
            @include mobile {
                font-size:$mfontsize;
                line-height:$mlineheight;
            }
            &.rounded {
                border-radius:($lineheight / 2 + 1);
            }
        }
    }
    &.full {
        display:block;
        width:100%;
    }
}
.btn-group {
    display:flex;
    margin-top:60px;
    justify-content:center;
    &.high {
        margin-top:120px;
        @include mobile {
            margin-top:60px;
        }
    }
    > * {
        min-width:200px;
        margin-left:10px;
        &:first-child {
            margin-left:0;
        }
    }
    @include mobile {
        margin-top:40px;
        > * {
            min-width:inherit;
            flex:1;
            margin-left:5px;
            &:first-child {
                margin-left:0;
            }
        }
    }
}

/* text link */
@each $color, $value, $ratio in $colors {
    .link-#{$color} {
        display:inline-block;
        vertical-align:middle;
        color:$value;
        font-size:inherit;
        font-weight:700;
        transition:$btn-transition;
        vertical-align:top;
        @include skew;
        @include pc {
            &:hover, 
            &:active {
                text-decoration:underline;
                @if $ratio == 'dark' {
                    color:tint-color($value, 15%);
                } @else if $ratio == 'bright' {
                    color:shade-color($value, 10%);
                }
            }
        }
    }
}