.selectbox-wrap {
    position:relative;
    .nice-select {
        position:relative;
        -webkit-tap-highlight-color: transparent;
        background-color: #fff;
        box-sizing: border-box;
        clear: both;
        cursor: pointer;
        display: block;
        font-size: 14px;
        height: 40px;
        line-height: 38px;
        border:1px solid $border;
        border-radius:$border-radius;
        outline: none;
        padding-left:14px;
        padding-right:24px;
        text-align: left !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        white-space: nowrap;
        width: auto;
        color:$basic;
        z-index:100;
        > span {
            display:block;
            @include skew;
        }
        &.open {
            border-color:$point;
            border-bottom-left-radius:0;
            border-bottom-right-radius:0;
            z-index:110;
        }
        @include tablet {
            padding-left:10px;
            font-size:13px;
        }
    }
    .nice-select:after {
        content: "";
        width:8px;
        height:8px;
        border:1px solid $gray;
        border-top:none;
        border-left:none;
        display: block;
        margin-top: -5px;
        pointer-events: none;
        position: absolute;
        right: 13px;
        top: 50%;
        transform:rotate(45deg);
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
    }
    .nice-select.open:after {
        transform: rotate(225deg);
        margin-top:-2px;
    }
    .nice-select.open .list {
        opacity: 1;
        pointer-events: auto;
        border-color:$point;
        border-radius:0 0 $border-radius $border-radius;
        -webkit-transform: scale(1) translateY(0);
        -ms-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        z-index:110;
    }
    .nice-select.disabled {
        background:$disabled;
        color: $gray;
        pointer-events: none;
    }
    .nice-select.disabled:after {
        border-color: #cccccc;
    }
    .nice-select .list {
        background-color: #fff;
        border:1px solid transparent;
        border-top:none;
        box-sizing: border-box;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: -1px;
        right:-1px;
        -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(0.75) translateY(-21px);
        -ms-transform: scale(0.75) translateY(-21px);
        transform: scale(0.75) translateY(-21px);
        z-index: 100;
    }
    .nice-select .list:hover .option:not(:hover) {
        background-color: transparent;
    }
    .nice-select .option {
        cursor: pointer;
        font-weight: 400;
        line-height: 40px;
        list-style: none;
        min-height: 40px;
        outline: none;
        padding-left: 14px;
        padding-right: 14px;
        text-align: left;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        @include skew;
        @include mobile {
            padding-left:10px;
            padding-right:10px;
        }
    }
    .nice-select .option:hover,
    .nice-select .option.focus,
    .nice-select .option.selected.focus {
        background-color: #f6f6f6;
    }
    .nice-select .option.selected {
        font-weight: bold;
    }
    .nice-select .option.disabled {
        background-color: transparent;
        color: #999;
        cursor: default;
    }
    
    .no-csspointerevents .nice-select .list {
        display: none;
    }
    
    .no-csspointerevents .nice-select.open .list {
        display: block;
    }
}


.slt-wrap {
    .nice-select {
        -webkit-tap-highlight-color: transparent;
        background-color: #fff;
        box-sizing: border-box;
        clear: both;
        cursor: pointer;
        display: block;
        font-size: 14px;
        height: 32px;
        line-height: 30px;
        border:1px solid #fff;
        outline: none;
        padding-left:10px;
        padding-right:24px;
        position: relative;
        text-align: left !important;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        white-space: nowrap;
        width: 100%;
        color:$placeholder;
        z-index:50;
        > span {
            display:block;
            @include skew;
        }
        &.open {
            border-color:$point;
            z-index:55;
        }
    }
    .nice-select:after {
        content: "";
        display: block;
        border-top:6px solid $placeholder;
        border-left:4px solid transparent;
        border-right:4px solid transparent;
        margin-top: -3px;
        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 50%;
        -webkit-transition: all 0.15s ease-in-out;
        transition: all 0.15s ease-in-out;
    }
    .nice-select.open:after {
        transform: rotate(180deg);
    }
    .nice-select.open .list {
        opacity: 1;
        pointer-events: auto;
        border-color:$point;
        -webkit-transform: scale(1) translateY(0);
        -ms-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
    }
    .nice-select.disabled {
        background:$disabled;
        color: $gray;
        pointer-events: none;
    }
    .nice-select.disabled:after {
        border-color: #cccccc;
    }
    .nice-select .list {
        background-color: #fff;
        border:1px solid transparent;
        border-top:none;
        box-sizing: border-box;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: -1px;
        right:-1px;
        -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(0.75) translateY(-21px);
        -ms-transform: scale(0.75) translateY(-21px);
        transform: scale(0.75) translateY(-21px);
        z-index: 9;
    }
    .nice-select .list:hover .option:not(:hover) {
        background-color: transparent;
    }
    .nice-select .option {
        cursor: pointer;
        font-weight: 400;
        line-height: 32px;
        list-style: none;
        min-height: 32px;
        outline: none;
        padding-left: 10px;
        padding-right: 10px;
        text-align: left;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
        @include skew;
    }
    .nice-select .option:hover,
    .nice-select .option.focus,
    .nice-select .option.selected.focus {
        background-color: #f6f6f6;
    }
    .nice-select .option.selected {
        font-weight: bold;
    }
    .nice-select .option.disabled {
        background-color: transparent;
        color: #999;
        cursor: default;
    }
    
    .no-csspointerevents .nice-select .list {
        display: none;
    }
    
    .no-csspointerevents .nice-select.open .list {
        display: block;
    }
}