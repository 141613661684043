*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin:0;
    padding:0;
}
html {
    color:$body-font-color;
    font-family: $font-family-square;
    font-size: $font-size-base;
    line-height:1;
    font-weight:normal;
}
textarea {
    resize: none;
}
fieldset,
img {
    border: 0;
}
img {
    width:auto;
    max-width:100%;
    height:auto;
    vertical-align: top;
}
li,
ol,
ul {
    list-style: none;
}
address,
em,
i {
    font-style: normal;
}
a {
    color:inherit;
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}
strong,
th,
b,
em {
    font-style:normal;
    font-weight:inherit;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
input,
select,
textarea,
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family:inherit;
    background:transparent;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
legend,
caption {
    visibility:hidden;
    overflow:hidden;
    width:0 !important;
    height:0 !important;
    font-size:0;
    line-height:0;
}
a,
button {
    &:focus {
        outline: 1px solid #333;
        &:not(:focus-visible) {
            outline: 0;
        }
    }
}
button {
    border:none;
    cursor:pointer;
}
.blind {
    position: absolute !important;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
}
.ft-noto {
    font-family: "Noto Sans KR" !important;
}
.ft-pen {
    font-family: "Nanum Pen Script", cursive !important;
}
.ft-mj {
    font-family: "Nanum Myeongjo", serif !important;
}