/* tab */
.bar-tab-wraps {
    margin-bottom:20px;
    overflow:hidden;
    @include tablet {
        margin-bottom:20px;
    }
    @include mobile {
        margin:0 -12px 15px;
        padding:0 12px;
    }
    .tabs {
        min-width:100%;
        display:inline-block;
        vertical-align:top;
        font-size:0;
        white-space:nowrap;
        li {
            position:relative;
            display:inline-block;
            vertical-align:top;
            padding:0 30px;
            @include tablet {
                padding:0 20px;
            }
            @include mobile {
                padding:0 12px;
            }
            &:first-child {
                padding-left:0;
            }
            &:last-child {
                padding-right:0;
            }
            &::before {
                content:' ';
                position:absolute;
                top:50%;
                left:0;
                width:1px;
                height:16px;
                margin-top:-8px;
                background:#cdcdcd;
            }
            &:first-child::before {
                display:none;
            }
            a {
                display:block;
                @include font;
            }
            &.active a {
                font-weight:900;
                color:$point;
            }
        }
    }
}