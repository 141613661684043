/* modal */
.modal {
    display:none;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    padding:0 20px;
    background:rgba(#000, 0.5);
    overflow:hidden;
    z-index:1000;
    outline:0;
    .modal-wrap {
        display:flex;
        align-items:center;
        width:auto;
        max-width:710px;
        height:calc(100% - 140px);
        margin:70px auto;
        .modal-container {
            display:flex;
            flex-direction:column;
            width:100%;
            max-height:100%;
            background:#fff;
            border-radius:10px;
            box-shadow: 5px 2px 10px 0px rgba(#000, 0.5);
            z-index:1010;
            .modal-header {
                display:flex;
                justify-content:space-between;
                align-items:center;
                padding:20px;
                @include mobile {
                    padding:10px 12px;
                }
                .modal-title {
                    padding-right:20px;
                    @include font-title;
                    font-weight:700;
                    @include textEllipsis;
                }
                .btn-close {
                    display:block;
                    position:relative;
                    width:30px;
                    height:30px;
                    &::before,
                    &::after {
                        content:' ';
                        position:absolute;
                        top:50%;
                        left:50%;
                        width:2px;
                        height:24px;
                        margin-top:-12px;
                        margin-left:-1px;
                        background:$basic;
                    }
                    &::before {
                        transform:rotate(45deg);
                    }
                    &::after {
                        transform:rotate(-45deg);
                    }
                }
            }
            .modal-body {
                max-height:100%;
                overflow-y:auto;
                padding:20px;
                @include mobile {
                    padding:20px 12px;
                }
            }
        }
    }
}

.video-modal-area {
    position:fixed;
    top:50%;
    left:50%;
    width:80%;
    max-width:710px;
    background:#fff;
    border-radius:10px;
    overflow:hidden;
    padding:60px 20px 50px;
    box-shadow: 5px 2px 10px 0px rgba(#000, 0.5);
    transform:translate(-50%, -50%);
    z-index:1010;
    @include mobile {
        width:90%;
        padding:50px 12px 40px;
    }
    .video-area {
        position:relative;
        width:100%;
        height:0;
        @include calcs(padding-top, 315px, 560px);
        background:#000;
        overflow:hidden;
        iframe {
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
        }
    }
    .video-info {
        margin-top:26px;
        .tit {
            position:relative;
            padding-top:16px;
            @include font-title;
            font-weight:700;
            &::before {
                content:' ';
                position:absolute;
                left:0;
                top:0;
                width:36px;
                height:4px;
                background:$point;
            }
        }
        .desc {
            margin-top:15px;
            color:$gray;
            @include font;
        }
        .date {
            margin-top:6px;
            color:$gray;
            @include font-sm;
        }
    }
    .btn-close {
        position:absolute;
        top:0;
        right:0;
        width:50px;
        height:50px;
        &::before,
        &::after {
            content:' ';
            position:absolute;
            width:2px;
            height:24px;
            bottom:10px;
            right:29px;
            background:$black;
            @include mobile {
                right:21px;
            }
        }
        &::before {
            transform:rotate(45deg);
        }
        &::after {
            transform:rotate(-45deg);
        }
    }
}