@charset "UTF-8";

// breakpoint
@mixin pc {
    @media screen and (min-width: 1025px) {
        @content;
    }
}
@mixin tablet {
    @media screen and (max-width: 1024px) {
        @content;
    }
}
@mixin exmob {
    @media screen and (min-width: 768px) {
        @content;
    }
}
@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}
@mixin mob {
    @media screen and (max-width: 480px) {
        @content;
    }
}

// font
@mixin font-sm {
    font-size:14px;
    line-height:22px;
    @include skew;
    @include mobile {
        font-size:13px;
    }
}
@mixin font {
    font-size:16px;
    line-height:24px;
    @include skew;
    letter-spacing:-0.3px;
    @include mobile {
        font-size:14px;
        line-height:22px;
    }
}
@mixin font-md {
    font-size:17px;
    line-height:25px;
    letter-spacing:-0.3px;
    @include mobile {
        font-size:15px;
        line-height:23px;
        @include skew;
    }
}
@mixin font-lg {
    font-size:18px;
    line-height:26px;
    letter-spacing:-0.3px;
    @include skew;
    @include mobile {
        font-size:16px;
        line-height:24px;
    }
}
@mixin font-xl {
    font-size:20px;
    line-height:28px;
    @include mobile {
        font-size:18px;
        line-height:26px;
    }
}
@mixin font-title {
    font-size:24px;
    line-height:32px;
    letter-spacing:-0.5px;
    @include mobile {
        font-size:20px;
        line-height:28px;
    }
}
@mixin font-giant {
    font-size:40px;
    line-height:44px;
    letter-spacing:-1.5px;
    font-weight:100;
    @include mobile {
        font-size:24px;
        line-height:28px;
        letter-spacing:-0.8px;
        font-weight:400;
    }
}
@mixin skew {
    transform: skew(-0.05deg);
}

// font family
@mixin font-noto {
    font-family: "Noto Sans KR" !important;
}
@mixin font-brush {
    font-family: "Nanum Brush Script", cursive !important;
}

// text ellipsis
@mixin textEllipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
    overflow: hidden;
}
@mixin textEllipsisClamp($line-clamp) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;
}

// clearfix
@mixin clearfix {
    &::after {
        content: " ";
        display: block;
        clear: both;
    }
}

// percent calc
@mixin calcs($property, $size, $wrap) {
    #{$property}: (($size * 100%) / $wrap);
}

// max-width 공통화
@mixin contArea {
    max-width: 1400px + 80px;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
    @include tablet {
        max-width: 100%;
        padding-left:20px;
        padding-right:20px;
    }
    @include mobile {
        padding-left: 12px;
        padding-right: 12px;
    }
}
