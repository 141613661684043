/* utilities */
$utilities: () !default;
$utilities: map-merge(
    (
        "align": (
            property: vertical-align,
            class: align,
            values: baseline top middle bottom text-bottom text-top,
        ),
        "float": (
            responsive: true,
            property: float,
            values: (
                start: left,
                end: right,
                none: none,
            ),
        ),
        "overflow": (
            property: overflow,
            values: auto hidden visible scroll,
        ),
        "display": (
            responsive: true,
            print: true,
            property: display,
            class: d,
            values: inline inline-block block grid table table-row table-cell flex inline-flex none,
        ),
        "border": (
            property: border,
            values: (
                null: $border-width solid $border,
                0: 0,
            ),
        ),
        "border-top": (
            property: border-top,
            values: (
                null: $border-width solid $border,
                0: 0,
            ),
        ),
        "border-end": (
            property: border-right,
            class: border-right,
            values: (
                null: $border-width solid $border,
                0: 0,
            ),
        ),
        "border-bottom": (
            property: border-bottom,
            values: (
                null: $border-width solid $border,
                0: 0,
            ),
        ),
        "border-start": (
            property: border-left,
            class: border-left,
            values: (
                null: $border-width solid $border,
                0: 0,
            ),
        ),
        "border-color": (
            property: border-color,
            class: border,
            values:
                map-merge(
                    $color-maps,
                    (
                        "white": $white,
                    )
                ),
        ),
        "border-width": (
            property: border-width,
            class: border,
            values: $border-widths,
        ),
        "width": (
            property: width,
            class: w,
            values: (
                25: 25%,
                50: 50%,
                75: 75%,
                100: 100%,
                auto: auto,
            ),
        ),
        "max-width": (
            property: max-width,
            class: mw,
            values: (
                100: 100%,
            ),
        ),
        "height": (
            property: height,
            class: h,
            values: (
                25: 25%,
                50: 50%,
                75: 75%,
                100: 100%,
                auto: auto,
            ),
        ),
        "max-height": (
            property: max-height,
            class: mh,
            values: (
                100: 100%,
            ),
        ),
        "flex": (
            responsive: true,
            property: flex,
            values: (
                fill: 1 1 auto,
            ),
        ),
        "flex-direction": (
            responsive: true,
            property: flex-direction,
            class: flex,
            values: row column row-reverse column-reverse,
        ),
        "flex-grow": (
            responsive: true,
            property: flex-grow,
            class: flex,
            values: (
                grow-0: 0,
                grow-1: 1,
            ),
        ),
        "flex-shrink": (
            responsive: true,
            property: flex-shrink,
            class: flex,
            values: (
                shrink-0: 0,
                shrink-1: 1,
            ),
        ),
        "flex-wrap": (
            responsive: true,
            property: flex-wrap,
            class: flex,
            values: wrap nowrap wrap-reverse,
        ),
        "justify-content": (
            responsive: true,
            property: justify-content,
            values: (
                start: flex-start,
                end: flex-end,
                center: center,
                between: space-between,
                around: space-around,
                evenly: space-evenly,
            ),
        ),
        "align-items": (
            responsive: true,
            property: align-items,
            values: (
                start: flex-start,
                end: flex-end,
                center: center,
                baseline: baseline,
                stretch: stretch,
            ),
        ),
        "align-content": (
            responsive: true,
            property: align-content,
            values: (
                start: flex-start,
                end: flex-end,
                center: center,
                between: space-between,
                around: space-around,
                stretch: stretch,
            ),
        ),
        "align-self": (
            responsive: true,
            property: align-self,
            values: (
                auto: auto,
                start: flex-start,
                end: flex-end,
                center: center,
                baseline: baseline,
                stretch: stretch,
            ),
        ),
        "order": (
            responsive: true,
            property: order,
            values: (
                first: -1,
                0: 0,
                1: 1,
                2: 2,
                3: 3,
                4: 4,
                5: 5,
                last: 6,
            ),
        ),
        "margin": (
            responsive: true,
            property: margin,
            class: m,
            values:
                map-merge(
                    $spacers,
                    (
                        auto: auto,
                    )
                ),
        ),
        "margin-x": (
            responsive: true,
            property: margin-right margin-left,
            class: mx,
            values:
                map-merge(
                    $spacers,
                    (
                        auto: auto,
                    )
                ),
        ),
        "margin-y": (
            responsive: true,
            property: margin-top margin-bottom,
            class: my,
            values:
                map-merge(
                    $spacers,
                    (
                        auto: auto,
                    )
                ),
        ),
        "margin-top": (
            responsive: true,
            property: margin-top,
            class: mt,
            values:
                map-merge(
                    $spacers,
                    (
                        auto: auto,
                    )
                ),
        ),
        "margin-right": (
            responsive: true,
            property: margin-right,
            class: mr,
            values:
                map-merge(
                    $spacers,
                    (
                        auto: auto,
                    )
                ),
        ),
        "margin-bottom": (
            responsive: true,
            property: margin-bottom,
            class: mb,
            values:
                map-merge(
                    $spacers,
                    (
                        auto: auto,
                    )
                ),
        ),
        "margin-left": (
            responsive: true,
            property: margin-left,
            class: ml,
            values:
                map-merge(
                    $spacers,
                    (
                        auto: auto,
                    )
                ),
        ),
        "padding": (
            responsive: true,
            property: padding,
            class: p,
            values: $spacers,
        ),
        "padding-x": (
            responsive: true,
            property: padding-right padding-left,
            class: px,
            values: $spacers,
        ),
        "padding-y": (
            responsive: true,
            property: padding-top padding-bottom,
            class: py,
            values: $spacers,
        ),
        "padding-top": (
            responsive: true,
            property: padding-top,
            class: pt,
            values: $spacers,
        ),
        "padding-right": (
            responsive: true,
            property: padding-right,
            class: pr,
            values: $spacers,
        ),
        "padding-bottom": (
            responsive: true,
            property: padding-bottom,
            class: pb,
            values: $spacers,
        ),
        "padding-left": (
            responsive: true,
            property: padding-left,
            class: pl,
            values: $spacers,
        ),
        "font-weight": (
            property: font-weight,
            class: fw,
            values: (
                light: 300,
                lighter: 100,
                normal: 400,
                medium: 500,
                bold: 700,
                bolder: 900,
            ),
        ),
        "text-align": (
            responsive: true,
            property: text-align,
            class: align,
            values: (
                left: left,
                right: right,
                center: center,
            ),
        ),
        "text-decoration": (
            property: text-decoration,
            values: none underline line-through,
        ),
        "text-transform": (
            property: text-transform,
            class: text,
            values: lowercase uppercase capitalize,
        ),
        "white-space": (
            property: white-space,
            class: text,
            values: (
                wrap: normal,
                nowrap: nowrap,
            ),
        ),
        "color": (
            property: color,
            class: text,
            values:
                map-merge(
                    $color-maps,
                    (
                        "black-50": rgba($black, 0.5),
                        "white-50": rgba($white, 0.5),
                        "reset": inherit,
                    )
                ),
        ),
        "background-color": (
            property: background-color,
            class: bg,
            values:
                map-merge(
                    $color-maps,
                    (
                        "transparent": transparent,
                    )
                ),
        ),
        "visibility": (
            property: visibility,
            class: null,
            values: (
                visible: visible,
                invisible: hidden,
            ),
        ),
    ),
    $utilities
);

@each $key, $utility in $utilities {
    @if type-of($utility) == "map" {
        @include generate-utility($utility, "");
    }
}
